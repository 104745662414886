@import 'styles/base/variables';
@import 'styles/base/mixins';

.singleImage {
  position: relative;

  :global(img) {
    width: 100%;
  }
}

.hoverImage {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  width: 100%;

  &:global(.fade-enter) {
    opacity: 0;
    visibility: hidden;
  }

  &:global(.fade-enter-done) {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease-in;
  }

  &:global(.fade-exit) {
    opacity: 1;
    visibility: visible;
  }

  &:global(.fade-exit-active) {
    opacity: 0;
    transition: opacity 0.3s ease-out;
  }

  &:global(.fade-exit-done) {
    visibility: hidden;
  }
}

:local(.buttonWrapper) {
  bottom: 0.625rem;
  position: absolute;
  right: 0.625rem;
  z-index: 10;

  @include screen-md {
    bottom: $spacing-xs;
    right: $spacing-xs;
  }

  &.active {
    :global(.plain) {
      background-color: $gb_grey_600;

      :global(i::before) {
        color: $gb_white;
        transform: rotate(180deg);
      }
    }
  }

  :global(.plain) {
    background-color: $gb_white;
    border-radius: 50%;
    border: 1px solid $gb_grey_700;
    padding: 0.3125rem;
    height: 1.875rem;

    @include screen-sm {
      height: auto;
    }
  }

  :global(i::before) {
    color: $gb_grey_600;
    font-size: 1.125rem;
    vertical-align: super;

    @include screen-sm {
      font-size: 2rem;
      vertical-align: middle;
    }
  }
}
